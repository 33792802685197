<template>
  <div>
    <base-section
      id="serviceprovidermyresource-account"
      space="16"
    >
      <!--- Dialog to create a ressource  --------------------------------------------------------->
      <v-dialog
        v-model="dialog"
        persistent
        scrollable
        max-width="800px"
      >
        <v-card
          class="rounded-xl pa-3"
        >
          <v-card-title>
            <base-info-card
              :title="$t('button.newRessource')"
              color="primary"
            />
          </v-card-title>
          <v-card-text>
            <!----- Details of the resource  ------->
            <v-row>
              <kdw-text-field
                id="IDfirstname"
                key="IDfirstname"
                v-model="myTemporaryResource.firstname"
                class="mt-4"
                :label="$t('identity.firstname')"
                prepend-icon="mdi-account-circle"
              />
            </v-row>
            <v-row>
              <kdw-text-field
                id="IDlastname"
                key="IDlastname"
                v-model="myTemporaryResource.lastname"
                :label="$t('identity.lastname')"
                prepend-icon="mdi-account-circle"
              />
            </v-row>
            <v-row>
              <kdw-text-field
                id="IDResourcemail"
                key="IDResourcemail"
                v-model="myTemporaryResource.email"
                :label="$t('identity.mail')"
                :rules="rulesEMail"
                prepend-icon="mdi-email"
              />
            </v-row>
            <v-row>
              <kdw-vue-tel-input
                v-model="myTemporaryResource.phone"
                select-id="phoneNumRessource"
              />
              <!-- <v-input
                prepend-icon="mdi-phone"
              >
                <vue-tel-input
                  v-model="myTemporaryResource.phone"
                  autoDefaultCountry:="false"
                  defaultCountry="FR"
                />
              </v-input>
              -->
              <!-- <kdw-text-field
                id="IDphone"
                key="IDphone"
                v-model="myTemporaryResource.phone"
                :label="$t('identity.phone')"
                prepend-icon="mdi-phone"
              /> -->
            </v-row>
            <v-row>
              <v-checkbox
                id="canBookOnline"
                key="canBookOnline"
                v-model="myTemporaryResource.canBookOnline"
                class="ml-1"
                :label="$t('ressources.bookingExternally')"
              />
            </v-row>
            <v-row>
              <v-checkbox
                id="webSiteAccess"
                key="webSiteAccess"
                v-model="myTemporaryResource.webSiteAccess"
                class="ml-1"
                :label="$t('ressources.visibleExternally')"
              />
            </v-row>
            <v-row
              v-if="myTemporaryResource.webSiteAccess === true"
            >
              <kdw-text-field
                id="IDlogin"
                key="IDlogin"
                v-model="myTemporaryResource.login"
                :label="$t('ressources.idLogin')"
                prepend-icon="mdi-login"
              />
            </v-row>
          </v-card-text>
          <!---  Define the actions of the Ressource Dialog     -->
          <v-card-actions>
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-spacer />
              </v-col>
              <v-col>
                <kdw-btn
                  id="btnCancelDialogRessource"
                  text
                  outlined
                  @click="close"
                >
                  {{ $t('button.cancel') }}
                </kdw-btn>
              </v-col>
              <v-col>
                <kdw-btn
                  id="btnSaveDialogRessource"
                  @click="save()"
                >
                  {{ $t('ressources.ressourceCreation') }}
                </kdw-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!------- Management of the status bar ------------------------>
      <v-container>
        <kdw-Statusbar-card
          v-model="snackbarUpdate"
          :snackbar-timeout="snackbarTimeout"
          :snackbar-message="snackbarMessage"
        />
        <!------- Add ressource button ------------------------>
        <!-- color="orange lighten-2" -->
        <v-row
          justify="end"
        >
          <kdw-btn
            id="btnNewRessource"
            class="mb-2 mr-5"
            @click="addRessource()"
          >
            {{ $t('button.newRessource') }}
          </kdw-btn>
        </v-row>
        <!------- Display of the different elements ------------------------>
        <v-row
          v-for="(myResource, r) in myResourcesList.list"
          :key="'row' + r"
          dense
        >
          <kdw-dialog-resource
            :key="'ressource' + refreshFront"
            v-model="myResourcesList.list[r]"
            :index="r"
            @deleteResource="deleteResource(r)"
            @updateResource="updateResource(r, $event)"
          />
        </v-row>
      </v-container>
    </base-section>
  </div>
</template>

<script>
  import { Vue, Component, Watch } from 'vue-property-decorator'
  import { Resource } from '@/services/Resource'
  import { ResourceList } from '@/services/ResourceList'
  import { VueTelInput } from 'vue-tel-input'

  // Vue.forceUpdate()

  @Component({
    name: 'ServiceProviderMyResourceAccount',
    components: {
      VueTelInput,
    },
  })
  class ServiceProviderMyResourceAccount extends Vue {
    snackbarUpdate = false
    snackbarMessage = ''
    snackbarTimeout = 2000
    test = true
    localPhonedetails = {}

    headers = [{ text: this.$i18n.t('identity.firstname'), align: 'start', sortable: true, value: 'firstname' },
               { text: this.$i18n.t('identity.lastname'), value: 'lastname' },
               { text: this.$i18n.t('hero.actionTable'), value: 'actions', sortable: false },
    ]

    // This table is organise by week, each item is a day, starting with monday and containing a table with a list of working hours and break per day. 7 days, from monday to sunday
    /*
    weekWorkingDays = [[{ type: 'Working', startHH: '10', startMM: '00', endHH: '17', endMM: '00' },
                        { type: 'Break', startHH: '12', startMM: '00', endHH: '14', endMM: '00' }],
                       [{ type: 'Working', startHH: '09', startMM: '00', endHH: '17', endMM: '00' },
                        { type: 'Break', startHH: '13', startMM: '00', endHH: '14', endMM: '00' }],
                       [{ type: 'Working', startHH: '09', startMM: '00', endHH: '17', endMM: '00' },
                        { type: 'Break', startHH: '13', startMM: '00', endHH: '14', endMM: '00' }],
                       [{ type: 'Working', startHH: '09', startMM: '00', endHH: '17', endMM: '00' },
                        { type: 'Break', startHH: '13', startMM: '00', endHH: '14', endMM: '00' }],
                       [{ type: 'Working', startHH: '09', startMM: '00', endHH: '17', endMM: '00' },
                        { type: 'Break', startHH: '13', startMM: '00', endHH: '14', endMM: '00' }],
                       [{ type: 'Working', startHH: '12', startMM: '00', endHH: '16', endMM: '00' },
                        { type: 'Break', startHH: '09', startMM: '00', endHH: '10', endMM: '00' }],
                       [{ type: 'Working', startHH: '12', startMM: '00', endHH: '16', endMM: '00' },
                        { type: 'Break', startHH: '08', startMM: '00', endHH: '12', endMM: '00' }],
    ]

    dayOffList = [{ type: 'rangeByDate', startDate: '2021-09-02', endDate: '2021-09-10' },
                  { type: 'rangeBytime', startDate: '2021-09-03', startHH: '13', startMM: '00', endHH: '14', endMM: '00' }]
    */

    myResourcesList = new ResourceList()
    myTemporaryResource = new Resource()

    search = ''
    dialog = false

    refreshFront = 0

    rulesEMail = [
      v => !!(v || '').match(/@/) || this.$i18n.t('AccountSP.mailFormat'),
    ]

    @Watch('dialog')
    dialogD (val) {
      val || this.close()
    }

    @Watch('myTemporaryResource.email')
    myTemporaryResourceChanged (val, oldval) {
      if (oldval !== val) {
        this.myTemporaryResource.login = val
      }
    }

    created () {
      this.myResourcesList.loadResourcesSP()
        .then(() => {
          this.refreshFront++
        })
    }

    updateResource (index, resource) {
      this.myResourcesList.updateResource(resource, index)
        .then((result) => {
          if (result) {
            this.alertSnackBar('ressources.alertSucessUpdateRes')
          } else {
            this.alertSnackBar('ressources.alertFailUpdateRes')
          }
        })
    }

    deleteResource (index) {
      // This is confirmed so we can take care of deleting the ressource (based on event from the component)
      // console.log('this.myResourcesList.list avant')
      // console.log(this.myResourcesList.list)
      // console.log(index)
      this.myResourcesList.deleteResource(index)
        .then((result) => {
          if (result) {
            // console.log('this.myResourcesList.list après ok')
            // console.log(this.myResourcesList.list)
            this.alertSnackBar('ressources.alertSucessDeleteRes')
            // this.$forceUpdate()
          } else {
            this.alertSnackBar('ressources.alertFailDeleteRes')
          }
        })
    }

    addRessource () {
      this.dialog = true
    }

    close () {
      this.dialog = false
      console.log(this.myTemporaryResource.phone)
      console.log(this.localPhonedetails)
      // this.refreshFront++
      // this.myTemporaryResource.initialise()
    }

    phonedetails (phonedetails) {
      console.log(phonedetails)
      this.localPhonedetails = phonedetails
    }

    save () {
      /* TO DO
      // We check if the consent was given before we proceed
      if (this.consentOK !== '') {
        this.alertSnackBar(this.consentOK, -1)
        // alert(this.consentOK)
        this.close()
        return
      }

      // checkSPConsent()
      // .then(result => {
      //   console.log(result)
      //  if (result === false) {
      //    this.close()
      //    return
      //  }
      // Consent have been given, so we can move forward.
      */
      // if ((this.localPhonedetails.isValid === undefined) || (this.localPhonedetails.isValid === null) || (this.localPhonedetails.isValid === false)) {
      //   this.alertSnackBar('ressources.ressourcePhoneInvalid', -1)
      //   return
      // }

      // We check to see if we have the different field added.
      if (this.myTemporaryResource.canBeCreated() === false) {
        this.alertSnackBar('ressources.ressourceMissingParam', -1)
        return
      }
      // This is a ressource creation
      this.myTemporaryResource.createResource()
        .then((result) => {
          if (result) {
            // console.log('the ressource has been created')
            // console.log(this.myTemporaryResource)
            this.myResourcesList.addResource(this.myTemporaryResource)
            // console.log(this.myResourcesList.list)
            this.myTemporaryResource = new Resource()
            this.refreshFront++
            this.alertSnackBar('ressources.alertSucessCreateRes')
            this.close()
          } else {
            // console.log('the ressource has NOT been created')
            this.myTemporaryResource = new Resource()
            this.alertSnackBar('ressources.alertFailCreateRes')
          }
        }).catch((err) => {
          this.myTemporaryResource = new Resource()
          if (err.message === 'userAlreadyExist') {
            this.alertSnackBar('ressources.alertFailAlreadyExistRes')
          } else {
            this.alertSnackBar('ressources.alertFailCreateRes')
          }
        })
    }

    alertSnackBar (messagekey, snackbarTimeout = 2000) {
      this.snackbarMessage = this.$i18n.t(messagekey)
      this.snackbarUpdate = true
      this.snackbarTimeout = snackbarTimeout
    }
  }
  export default ServiceProviderMyResourceAccount
</script>
